// src/mixins/i18ImagesMixin.js

// This mixin is used to get the image path based on the current locale.
// example of use: :src="i18nImage('image-name')"
export default {
  methods: {
    i18nImage (image) {
      const basePath = this.i18nImageBasePath || 'page-img';
      const localePath = this.$i18n.locale === 'es' ? '' : `${this.$i18n.locale}/`;
      return require(`@/assets/images/${basePath}/${localePath}${image}`);
    }
  }
};
